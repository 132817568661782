import React from 'react';

import { Button } from 'src/components/atoms/Button';
import { InlineList } from 'src/components/molecules/InlineList';
import { Section } from 'src/components/molecules/Section';

export const LearnMoreSection = ({ developer }) => {
  return (
    <Section centered dark>
      <h2>Want to learn more?</h2>
      <InlineList centered>
        <li>
          <Button to="/product/rasa-platform/">Learn More</Button>
        </li>
        <li>
          {developer ? (
            <Button
              to="mailto:?subject=Contextual%20AI%20assistants%20with%20Rasa&body=Read%20more%20here%3A%20https://rasa.com/solutions/on-premises-chatbots/"
              secondary
            >
              Send a link to your developers
            </Button>
          ) : (
            <Button secondary to="/connect-with-rasa/">
              Talk with Sales
            </Button>
          )}
        </li>
      </InlineList>
    </Section>
  );
};
