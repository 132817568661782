import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { Button } from 'src/components/atoms/Button';
import { LinkBox } from 'src/components/atoms/LinkBox';
import { Grid } from 'src/components/molecules/Grid';
import { Section } from 'src/components/molecules/Section';
import { LearnMoreSection } from 'src/components/organisms/LearnMoreSection';
import { FixedImg } from 'src/embeds/image/image-dato';

export function CaseStudyOverview() {
  const data = useStaticQuery(query);

  return (
    <>
      <Section>
        <Grid columns="1fr 1fr 1fr">
          {data.allDatoCmsCaseStudy.nodes.map((node) => (
            <LinkBox centered link={node.externalLink || `/customers/${node.slug}/`} key={node.heading}>
              {!!node.customer && (
                <FixedImg
                  constrain
                  width={Math.round((node.customer.logo.width / node.customer.logo.height) * 30)}
                  height={30}
                  data={node.customer.logo}
                  style={{ margin: '0 auto' }}
                />
              )}
              <br />
              <p style={{wordBreak: "keep-all"}}>{node.heading}</p>
              <Button type="button">Read more</Button>
            </LinkBox>
          ))}
        </Grid>
      </Section>
      <LearnMoreSection />
    </>
  );
}

const query = graphql`
  query {
    allDatoCmsCaseStudy(filter: { showInIndex: { eq: true } }, sort: { fields: position }) {
      nodes {
        ...GatsbyDatoCmsCaseStudyTeaser
      }
    }
  }
`;
